import React, { useState } from "react";
// import { Link } from "react-router-dom";

import "../css/NavbarFooter.css";
import "../css/reset.css";

import { AiOutlineDown } from "react-icons/ai";
// import '../css/animate.css'
// import '../css/bootstrap.min.css'
// import '../css/flaticon-2.css'
// import '../css/flaticon.css'
// import '../css/fontawesome-all.css'
// import '../css/jquery-ui.css'
// import '../css/jquery.mCustomScrollbar.min.css'
// import '../css/metisMenu.css'
// import '../css/nice-select.css'
// import '../css/rs6.css'
// import '../css/slick-theme.css'
// import '../css/slick.css'
// import '../css/twenty.css'
// import '../css/uikit.min.css'
// import '../css/video.min.css'
import { BsDot } from "react-icons/bs";
import logo from "../images/PNGFILE.png";
import phonelogo from "../images/call.svg";
import { FaGreaterThan } from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { IconContext } from "react-icons";
import { LiaGreaterThanSolid } from "react-icons/lia";

// import styles1 from "./navbarresponsive.module.css";

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownHelp, setDropdownHelp] = useState(false);
  const [dropdownwho, setDropdownwho] = useState(false);
  const [dropdownCleaning, setDropdownCleaning] = useState(false);

  const handleDropdownCclick = (e) => {
    setDropdownVisible(!dropdownVisible);
    // if (dropdownVisible) {
    //     e.target.classList.add(styles1.color);
    // } else {
    //     e.target.classList.remove(styles1.colorBlack);
    // }
  };
  const handleClickHelp = (e) => {
    setDropdownHelp(!dropdownHelp);
    // if (dropdownHelp) {
    //     e.target.classList.add(styles1.color);
    // } else {
    //     e.target.classList.remove(styles1.color);
    // }
  };
  const handleClickWho = (e) => {
    setDropdownwho(!dropdownwho);
    // if (dropdownwho) {
    //     e.target.classList.add(styles1.color);
    // } else {
    //     e.target.classList.remove(styles1.color);
    // }
  };
  const handleDropdownCommercialclick = () => {
    setDropdownCleaning(!dropdownCleaning);
  };
  return (
    <header className="header">
      <div
        className="header__main-wrap"
        data-uk-sticky="top: 250; animation: uk-animation-slide-top;"
      >
        {/* <div className="header__main-wrap"> */}
        <div className="container">
          <div className="header__main ul_li_between">
            <div
              className="header__bar hamburger_menu"
              style={{ marginLeft: "20px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#0abab5",
                }}
                onClick={showSidebar}
              >
                <FaIcons.FaBars style={{ color: " #f26a82" }} />
                <span>Menu</span>
              </div>
            </div>
            <div className="header__main-right">
              <div className="header__logo" style={{ marginRight: "20px" }}>
                <a href="https://mommybearcleaning.com.au/">
                  <img src={logo} alt="" />
                </a>
              </div>
            </div>
            <div className="main-menu navbar navbar-expand-lg">
              <nav className="main-menu__nav collapse navbar-collapse">
                <ul>
                  <li className="menu-item-has-children">
                    <a href="#!">Who we are</a>
                    <AiOutlineDown />
                    <ul className="submenu clearfix">
                      <li>
                        <a href="https://mommybearcleaning.com.au/about.html">
                          Our Story
                        </a>
                      </li>
                      {/* <li><a href="/">Our Team</a></li> */}
                      {/* <li>
                        <a href="https://mommybearcleaning.com.au/team.html">
                          Our Team
                        </a>
                      </li> */}
                      <li>
                        <a href="https://mommybearcleaning.com.au/ourmission.html">
                          Our Mission
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="menu-item-has-children">
                    <a href="#!">Services</a>
                    <AiOutlineDown />
                    <ul className="submenu clearfix">
                      <li className="menu-item-has-children">
                        <a href="#!">
                          Commercial Cleaning <LiaGreaterThanSolid />{" "}
                        </a>
                        <ul className="submenu clearfix">
                          <li>
                            <a href="https://mommybearcleaning.com.au/officecleaningservice.html">
                              Office Cleaning Services
                            </a>
                          </li>
                          <li>
                            <a href="https://mommybearcleaning.com.au/medicalcentres.html">
                              Medical Centers Cleaning
                            </a>
                          </li>
                          <li>
                            <a href="https://mommybearcleaning.com.au/factory.html">
                              Factory and Warehouse Cleaning
                            </a>
                          </li>
                          <li>
                            <a href="https://mommybearcleaning.com.au/childcare.html">
                              Childcare Centers
                            </a>
                          </li>
                          <li>
                            <a href="https://mommybearcleaning.com.au/pub.html">
                              Pub and Restaurants
                            </a>
                          </li>
                          <li>
                            <a href="https://mommybearcleaning.com.au/communitycentres.html">
                              Community Centres and Others
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="https://mommybearcleaning.com.au/oneofcleaning.html">
                          One-Off cleaning Services
                        </a>
                      </li>
                      <li>
                        <a href="https://mommybearcleaning.com.au/regularcleaningservices.html">
                          Regular Cleaning Services
                        </a>
                      </li>
                      <li>
                        <a href="https://mommybearcleaning.com.au/glasscleaning.html">
                          Glass Cleaning Services
                        </a>
                      </li>
                      <li>
                        <a href="https://mommybearcleaning.com.au/antivirus.html">
                          Anti Viral and Disinfecfectant Services
                        </a>
                      </li>
                      <li>
                        <a href="https://mommybearcleaning.com.au/endofleasecleaning.html">
                          End Of Lease Cleaning Services
                        </a>
                      </li>
                      <li>
                        <a href="https://mommybearcleaning.com.au/steam.html">
                          Steam/Carpet Cleaning
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* 
                                    <ul className="submenu clearfix">

                                        <li><a href="oneofcleaning.html">One-Off cleaning Services</a></li>
                                        <li><a href="regularcleaningservices.html">Regular Cleaning Services</a>
                                        </li>
                                        <li><a href="additional.html">Additional Cleaning Services</a></li>
                                        <li><a href="antivirus.html">Anti Viral and Disinfecfectant Services</a>
                                        </li>
                                        <li><a href="endofleasecleaning.html">End Of Lease Cleaning Services</a>
                                        </li>
                                        <li><a href="steam.html">Steam/Carpet Cleaning</a></li>

                                    </ul> */}

                  <li className="">
                    <a href="https://mommybearcleaning.com.au/whereweclean.html">
                      Where we clean
                    </a>
                  </li>
                  <li className="">
                    <a href="https://mommybearcleaning.com.au/pricing.html">
                      Pricing
                    </a>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#!">Help</a>
                    <AiOutlineDown />

                    <ul className="submenu clearfix">
                      <li>
                        <a href="https://mommybearcleaning.com.au/contact.html">
                          Contact Us
                        </a>
                      </li>
                      <li>
                        <a href="https://mommybearcleaning.com.au/faq.html">
                          FAQ
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header__main-right ul_li">
              <div className="header__cta ul_li">
                <div className="icon">
                  <img src={phonelogo} alt="" />
                </div>
                <div className="content">
                  <span>Need any help?</span>
                  <a href="tel:0403582550">0449 626 424</a>
                </div>
              </div>
              <div
                className="header__btn ml-40"
                style={{ marginRight: "15px" }}
              >
                <a
                  className="thm-btn thm-btn--transparent button br-0"
                  href="#!"
                >
                  <span className="btn-wrap">
                    <span style={{ color: "white" }}>BOOK NOW</span>
                    <span>BOOK NOW</span>
                  </span>
                </a>
                {/* <a href='/' className={styles1.menubars}>
                                    <FaIcons.FaBars onClick={showSidebar} />
                                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className={sidebar ? "navmenu active" : "navmenu"}>
        <ul className="navMenuItems">
          <li className="navbarToggle">
            <IconContext.Provider value={{ color: "white" }}>
              <div className="menubars" onClick={showSidebar}>
                <AiIcons.AiOutlineClose />
              </div>
            </IconContext.Provider>
          </li>
          <li>
            <div className="listitem">
              <a href="https://mommybearcleaning.com.au/index.html">
                <span>Home </span>
              </a>
            </div>
            <div className="listitem" onClick={handleClickWho}>
              <span>Who we are </span>
              <FaGreaterThan />
            </div>
            <div
              className={"dropdownCContent " + (dropdownwho ? "Aactive" : "")}
            >
              <ul>
                <IconContext.Provider value={{ size: "5%" }}>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/about.html">
                      Our Story
                    </a>
                  </li>
                  {/* <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/team.html">
                      Our Team
                    </a>{" "}
                  </li> */}
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/ourmission.html">
                      Our Mission
                    </a>
                  </li>
                </IconContext.Provider>
              </ul>
            </div>
          </li>
          <li>
            <div className="listitem" onClick={handleDropdownCclick}>
              <span>Services </span>
              <FaGreaterThan />
            </div>
            <div
              className={
                "dropdownCContent " + (dropdownVisible ? "Aactive" : "")
              }
            >
              <ul>
                <IconContext.Provider value={{ size: "5%" }}>
                  <li>
                    <IconContext.Provider value={{ size: "6%" }}>
                      <div
                        className="listitem"
                        onClick={handleDropdownCommercialclick}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <BsDot />
                        <span style={{ marginRight: "20px" }}>
                          {" "}
                          Commercial Cleaning{" "}
                        </span>
                        <IconContext.Provider value={{ size: "15px" }}>
                          <FaGreaterThan />
                        </IconContext.Provider>
                      </div>
                    </IconContext.Provider>
                    <div
                      className={
                        "dropdownCContent " +
                        (dropdownCleaning ? "Aactive" : "")
                      }
                    >
                      <ul style={{ marginLeft: "20px" }}>
                        <IconContext.Provider value={{ size: "5%" }}>
                          <li className="droplistitem">
                            <BsDot />
                            <a href="https://mommybearcleaning.com.au/officecleaningservice.html">
                              Office Cleaning Services
                            </a>
                          </li>
                          <li className="droplistitem">
                            <BsDot />
                            <a href="https://mommybearcleaning.com.au/medicalcentres.html">
                              Medical Centers Cleaning
                            </a>{" "}
                          </li>
                          <li className="droplistitem">
                            <BsDot />
                            <a href="https://mommybearcleaning.com.au/factory.html">
                              Factory and Warehouse Cleaning
                            </a>
                          </li>
                          <li className="droplistitem">
                            <BsDot />
                            <a href="https://mommybearcleaning.com.au/childcare.html">
                              Childcare Centers
                            </a>
                          </li>
                          <li className="droplistitem">
                            <BsDot />
                            <a href="https://mommybearcleaning.com.au/pub.html">
                              Pub and Restaurants
                            </a>
                          </li>
                          <li className="droplistitem">
                            <BsDot />
                            <a href="https://mommybearcleaning.com.au/communitycentres.html">
                              Community Centres and Others
                            </a>
                          </li>
                        </IconContext.Provider>
                      </ul>
                    </div>
                  </li>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/oneofcleaning.htmll">
                      One-Off cleaning Services
                    </a>
                  </li>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/regularcleaningservices.html">
                      Regular Cleaning Services
                    </a>
                  </li>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/glasscleaning.html">
                      Glass Cleaning Services
                    </a>
                  </li>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/antivirus.html">
                      Anti Viral and Disinfecfectant Services
                    </a>
                  </li>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/endofleasecleaning.html">
                      End Of Lease Cleaning Services
                    </a>
                  </li>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/steam.html">
                      Steam/Carpet Cleaning
                    </a>
                  </li>
                </IconContext.Provider>
              </ul>
            </div>
          </li>
          <li>
            <div className="listitem">
              <a href="https://mommybearcleaning.com.au/whereweclean.html">
                <span>Where we clean </span>
              </a>
            </div>
          </li>
          <li>
            <div className="listitem">
              <a href="https://mommybearcleaning.com.au/pricing.html">
                <span>Pricing</span>
              </a>
            </div>
          </li>
          <li>
            <div className="listitem" onClick={handleClickHelp}>
              <span>Help</span>
              <FaGreaterThan />
            </div>
            <div
              className={"dropdownCContent " + (dropdownHelp ? "Aactive" : "")}
            >
              <ul>
                <IconContext.Provider value={{ size: "5%" }}>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/contact.html">
                      Contact Us
                    </a>
                  </li>
                  <li className="droplistitem">
                    <BsDot />
                    <a href="https://mommybearcleaning.com.au/faq.html">FAQ</a>
                  </li>
                </IconContext.Provider>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
